export default class Paths {

    constructor(environmentMap, floorTexture, glbModel ,sources){

        // Options
        this.sources = sources
        this.environmentMap = environmentMap
        this.floorTexture = floorTexture
        this.glbModel = glbModel

        this.updateGlbModel()
        this.updateFloorTexture()
        this.updateEnvironmentMap()

        return this.sources
    }

    updateEnvironmentMap(){
        if(this.environmentMap){
            switch (this.environmentMap) {
                case 'space':
                    this.sources[0].path = [
                        'textures/environmentMaps/space/px.png',
                        'textures/environmentMaps/space/nx.png',
                        'textures/environmentMaps/space/py.png',
                        'textures/environmentMaps/space/ny.png',
                        'textures/environmentMaps/space/pz.png',
                        'textures/environmentMaps/space/nz.png'
                    ]
                  break;
                case 'cyberpunk':
                    this.sources[0].path = [
                        'textures/environmentMaps/cyberpunk/px.png',
                        'textures/environmentMaps/cyberpunk/nx.png',
                        'textures/environmentMaps/cyberpunk/py.png',
                        'textures/environmentMaps/cyberpunk/ny.png',
                        'textures/environmentMaps/cyberpunk/pz.png',
                        'textures/environmentMaps/cyberpunk/nz.png'
                    ]
                  break;
                case 'sky':
                    this.sources[0].path = [
                        'textures/environmentMaps/sky/px.png',
                        'textures/environmentMaps/sky/nx.png',
                        'textures/environmentMaps/sky/py.png',
                        'textures/environmentMaps/sky/ny.png',
                        'textures/environmentMaps/sky/pz.png',
                        'textures/environmentMaps/sky/nz.png'
                    ]
                  break;
                case 'office':
                    this.sources[0].path = [
                        'textures/environmentMaps/office/px.png',
                        'textures/environmentMaps/office/nx.png',
                        'textures/environmentMaps/office/py.png',
                        'textures/environmentMaps/office/ny.png',
                        'textures/environmentMaps/office/pz.png',
                        'textures/environmentMaps/office/nz.png'
                    ]
                    break;

                case 'black':
                    this.sources.shift()
                  break;
            }
        }

    }

    updateGlbModel()
    {
        if(this.glbModel){
            this.sources[1].path = this.glbModel;
        }
    }

    updateFloorTexture()
    {
        if(this.floorTexture){
            switch (this.floorTexture) {
                case 'dirt':
                    this.sources[8].path = 'textures/floor/stone/color.jpg'
                    this.sources[9].path = 'textures/floor/stone/normal.jpg'
                  break;
                case 'stone':
                    this.sources[8].path = 'textures/floor/stone/color.jpg'
                    this.sources[9].path = 'textures/floor/stone/normal.jpg'
                  break;
                case 'marble':
                    this.sources[8].path = 'textures/floor/marble/color.jpg'
                    this.sources[9].path = 'textures/floor/marble/normal.jpg'
                  break;

            }
        } else {
            this.sources.splice(9, 1);
            this.sources.splice(8, 1);
        }
    }

   
}