import * as THREE from 'three'
import Experience from "../Experience";
import { gsap } from "gsap";


export default class Avatar
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('Avatar')
        }
    
        // Setup
        this.avatar = this.resources.items.avatarModel

        this.setModel()
        this.setAnimation()
        this.playEyesBlink()
        this.playAnimation()
        this.setMouthAnimation()

        this.listenerAnimations()
        this.listenerAudio()


    }

    setModel()
    {
        
        this.model = this.avatar.scene
        this.model.rotation.y = 0.14

        this.model.traverse((object) => {
            if (object.isBone) {
              if (object.name === 'LeftEye') {
                this.model.leftEyeBone = object;
              }
              if (object.name === 'RightEye') {
                this.model.rightEyeBone = object;
              }
            }
        });

        this.scene.add(this.model)

        this.model.traverse((child) => {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = true
            }
        })
    }

    setAnimation()
    {
        this.animation = {}
        this.animation.mixer = new THREE.AnimationMixer(this.model)
        this.animation.actions = {}
        this.animationSources = [
            'avatarAnimationIdle', 
            'avatarAnimationTalking',
            'avatarAnimationHello',
            'avatarAnimationThank',
            'avatarAnimationSitting',
            'avatarAnimationAngry',
        ]

        for (let filename of this.animationSources) {
            const animationFile = this.resources.items[filename]
            const animationName = filename;

            for (let i = 0; i < animationFile.animations.length; i++) {
                animationFile.animations[i].name = animationName
                this.animation.actions[animationName] = this.animation.mixer.clipAction(animationFile.animations[i])
            }
        }
        
        this.animation.actions.current = this.animation.actions[this.animationSources[0]];
        this.animation.actions.current.play();
    }

    playEyesBlink()
    {
        const blinksPerMinute = 17; // Valore medio tra 15 e 20
        const averageBlinkInterval = (60 / blinksPerMinute) * 1000;

        const blinkIntervalVariance = 0.3; // Variabilità dell'intervallo di tempo (30%)
        const minBlinkInterval = averageBlinkInterval * (1 - blinkIntervalVariance);
        const maxBlinkInterval = averageBlinkInterval * (1 + blinkIntervalVariance);
      
        this.model.animateEyes = (open) =>
        {
            if (!this.model.leftEyeBone || !this.model.rightEyeBone) {
              console.error('Eye bones not found.');
              return;
            }
          
            const targetRotation = open ? 0 : 0.8; // Chiusura degli occhi
          
            this.model.leftEyeBone.rotation.x = targetRotation;
            this.model.rightEyeBone.rotation.x = targetRotation;
        }

        setTimeout(() => {
          // Chiudi gli occhi
          this.model.animateEyes(false);
      
          setTimeout(() => {
            // Apri gli occhi
            this.model.animateEyes(true);
      
            // Continua a lampeggiare gli occhi casualmente
            this.playEyesBlink();
          }, 200); // Durata del lampeggiamento (ms)
        }, Math.random() * (maxBlinkInterval - minBlinkInterval) + minBlinkInterval);
    }

    playAnimation()
    {
        this.animation.play = (name) =>
        {
            const newAction = this.animation.actions[name]
            const oldAction = this.animation.actions.current

            if(newAction != oldAction){
                newAction.reset()
                newAction.play()
                newAction.crossFadeFrom(oldAction, 0.6)

                this.animation.actions.current = newAction
            }
        }

        // Debug
        if(this.debug.active)
        {
            const debugObject = {}
           
            for (let filename of this.animationSources) {
                const animationName = filename;
                const labelName = animationName.replace(/^avatarAnimation/, '');
                const functionName = `play${labelName}`;
                
                debugObject[functionName] = () => {
                  this.animation.play(animationName);
                };
                this.debugFolder.add(debugObject, functionName);
            }

            this.debugFolder
                .add(this.model.rotation, 'y')
                .name('avatarRotationY')
                .min(-5)
                .max(5)
                .step(0.001)
            
        }
    }

    setMouthAnimation()
    {
        this.model.animateMouth = (smileValue, openValue) =>
        {        
            // Trova il mesh della testa
            // Tool per individuare i Morph Targets -> https://gltf-viewer.donmccurdy.com/
            this.model.headMesh = this.model.getObjectByName('Wolf3D_Avatar');
            if (!this.model.headMesh) {
                console.error('Head mesh not found.');
                return;
            }
        
            // Modifica i valori dei morph targets
            if (this.model.headMesh.morphTargetDictionary.hasOwnProperty('mouthSmile')) {
                this.model.headMesh.morphTargetInfluences[this.model.headMesh.morphTargetDictionary['mouthSmile']] = smileValue;
            }
            if (this.model.headMesh.morphTargetDictionary.hasOwnProperty('mouthOpen')) {
                this.model.headMesh.morphTargetInfluences[this.model.headMesh.morphTargetDictionary['mouthOpen']] = openValue;
            }

        }
    }

    listenerAnimations()
    {
        document.addEventListener("animation", (event) =>
        {
            console.log(event);
            this.animation.play(event.detail.animation)
            if(event.detail.animation != "avatarAnimationIdle" && event.detail.repeation != 0){
                setTimeout( () => {
                    this.animation.play("avatarAnimationIdle")
                }, event.detail.duration);
            }
        })

        window.addEventListener('message', (event) =>
        {
            if (event.data.event === 'animation') {
                this.animation.play(event.data.detail.animation)
            }

  
        });
          
    }

    listenerAudio()
    {
        let currentAudioElement = null;
        document.addEventListener("audio", (event) => 
        {
            if(event.detail.audio == "mute"){
                if (currentAudioElement !== null && !currentAudioElement.paused) {
                    currentAudioElement.pause();
                }
                return;
            } else {

                // Web Audio API setup
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const audioElement = event.detail.audio;
                const audioSourceNode = audioContext.createMediaElementSource(audioElement);
                const analyserNode = audioContext.createAnalyser();
                analyserNode.fftSize = 1024;
                const bufferLength = analyserNode.frequencyBinCount;
                const dataArray = new Uint8Array(bufferLength);
                audioSourceNode.connect(analyserNode);
                audioSourceNode.connect(audioContext.destination);

                // Interruzione dell'audio corrente, se presente
                if (currentAudioElement !== null && !currentAudioElement.paused) {
                    currentAudioElement.pause();
                }
            
                
                var requestId = undefined;
                function animate() {
                    const experience = window.experience
                    const model = experience.world.avatar;
                    
                    requestId = requestAnimationFrame(animate);
            
                    // Aggiorna i morph targets in base all'audio
                    updateMouthFromAudio(model);
                }
                animate(); 

                setTimeout(() => {
                    animateStop()
                    this.model.animateMouth(0, 0);
                }, (audioElement.duration * 1000));

                function animateStop(){
                    if(requestId){
                        requestId = window.cancelAnimationFrame(requestId);
                        requestId = undefined;
                    }
                }

                function updateMouthFromAudio(avatar) {
                    analyserNode.getByteFrequencyData(dataArray);
            
                    // Calcola il volume medio del segnale audio
                    let sum = 0;
                    for (let i = 0; i < bufferLength; i++) {
                        sum += dataArray[i];
                    }
                    const averageVolume = sum / bufferLength;
            
                    // Mappa il volume medio a un valore tra 0 e 1 per l'apertura della bocca
                    const scaleFactor = 4; // Aumenta questo valore per aumentare l'apertura della bocca
                    const threshold = 0.12; // Aumenta questo valore per eliminare i valori molto bassi
                    let openValue = Math.min(1, averageVolume / 255 * scaleFactor);
                    openValue = openValue < threshold ? 0 : openValue;
            
                    // Mappa la variazione del volume a un valore tra 0 e 1 per il sorriso
                    const smileScaleFactor = 2.5; // Aumenta questo valore per aumentare l'intensità del sorriso
                    const smileThreshold = 0.12; // Aumenta questo valore per eliminare i valori molto bassi
                    let smileValue = Math.min(1, averageVolume / 255 * smileScaleFactor);
                    smileValue = smileValue < smileThreshold ? 0 : smileValue;
            
                    //console.log(smileValue, openValue);
                    avatar.model.animateMouth(smileValue, openValue);
                }
                    
                audioElement.play(); // Riproduce l'audio

                // Memorizzazione del riferimento all'audio corrente
                currentAudioElement = audioElement;

                audioElement.onended = () => {

                    // Memorizzazione del riferimento all'audio corrente
                    currentAudioElement = null;
                    
                    // Seleziona l'elemento <iframe> tramite l'ID
                    const iframe = document.getElementById('controller');

                    // Verifica se l'elemento <iframe> esiste e ha l'attributo 'data-type' impostato su 'audio'
                    if (iframe && iframe.getAttribute('data-type') === 'audio') {
                        
                        iframe.contentWindow.postMessage('startlistening', '*');

                    } else {
                        console.log('L\'iframe con ID "controller" non è di tipo audio o non esiste.');
                    }


                }  
            }

        });
    }


    update()
    {
        this.animation.mixer.update(this.experience.time.delta * 0.001)
    }
}